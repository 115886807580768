@import 'components.less';
@charset "UTF-8";

.section_main {
    .def;
	background-position: center;
	background-size: cover;
	text-align: center;
	background: #fff;
	.shadow-bg {
		padding-top: 25px;
		background: rgba(67,111,221, .7);
		min-height: 450px;
		color: #fff;
	}
	h1 {
		font-weight: 700;
	}
	p {
		font-size: .9rem;
	}
	.icon {
		width: 90px;
		height: 90px;
		border-radius: 50%;
		background: @primary;
		margin: 50px auto 25px auto;
		svg {
			width: 90px;
			height: 90px;
			fill: #fff;
		}
	}
}

.section_content {
	.def;
	padding-bottom: 35px;
	.box_content {
		width: 100%;
		max-width: 1000px;
		border-radius: 8px;
		margin: -80px auto auto auto;
		padding: 15px;
		background: #fff;
		min-height: 100vh;
		font-size: 1rem;
		.shadow-3;
	}
}

footer {
	.def;
	background: #eee;
	padding: 15px;
	font-size: .8rem;
	color: @default;
	text-align: center;
}

@media all and (max-width: 1199px) {
	#social {
		width: 100%;
		bottom: 0;
		flex-direction: row;
	}
	#social .s-link {
		width: 25%;
	}
	#social .s-link:hover {
		width: 25%;
	}
}

@media all and (max-width: 991px) {
    
}

@media all and (max-width: 767px) {
    
}
@media all and (max-width: 575px) {

    h1, h2, h3 {
        font-size: 30px !important;
    }
}