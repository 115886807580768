@charset "UTF-8";
body,
html {
  font-family: 'Montserrat', sans-serif;
  margin: 0 auto !important;
  padding: 0 !important;
  font-size: 100%;
  font-weight: normal;
  font-display: swap;
  color: #454544;
  background: #eee;
}
p {
  word-wrap: break-word !important;
}
*:focus,
*:hover {
  outline-color: transparent !important;
}
img {
  max-width: 100%;
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}
.shadow-0 {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}
.shadow-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.12);
}
.shadow-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.shadow-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.shadow-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
ul.custom {
  list-style: square;
  color: #4bbe50;
  padding-left: 15px;
}
ul.custom li {
  margin: 2px 0;
}
ul.custom li span {
  color: #454544;
}
h1,
h2,
h3,
h4 {
  font-weight: 700;
}
b {
  color: #4bbe50;
}
blockquote {
  border-left: 3px solid #4bbe50;
  padding: 5px 8px;
  font-size: .95rem;
  font-style: italic;
  background: #f8f8f8;
}
#social {
  position: fixed;
  left: 0;
  bottom: calc(50% - 120px);
  width: 48px;
  display: flex;
  flex-direction: column;
  z-index: 9;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
#social .s-link {
  width: 48px;
  height: 48px;
  padding: 13px 0;
  background: #fff;
  transition: width 0.15s ease-in-out;
}
#social .s-link svg {
  display: block;
  margin: 0 auto;
  width: 22px;
  height: 22px;
  fill: #666;
}
#social .s-link:hover {
  width: 65px;
}
#social .s-link:hover svg {
  fill: #436fdd;
}
.section_main {
  position: relative;
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  text-align: center;
  background: #fff;
}
.section_main .shadow-bg {
  padding-top: 25px;
  background: rgba(67, 111, 221, 0.7);
  min-height: 450px;
  color: #fff;
}
.section_main h1 {
  font-weight: 700;
}
.section_main p {
  font-size: .9rem;
}
.section_main .icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #436fdd;
  margin: 50px auto 25px auto;
}
.section_main .icon svg {
  width: 90px;
  height: 90px;
  fill: #fff;
}
.section_content {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 35px;
}
.section_content .box_content {
  width: 100%;
  max-width: 1000px;
  border-radius: 8px;
  margin: -80px auto auto auto;
  padding: 15px;
  background: #fff;
  min-height: 100vh;
  font-size: 1rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
footer {
  position: relative;
  width: 100%;
  height: auto;
  background: #eee;
  padding: 15px;
  font-size: .8rem;
  color: #454544;
  text-align: center;
}
@media all and (max-width: 1199px) {
  #social {
    width: 100%;
    bottom: 0;
    flex-direction: row;
  }
  #social .s-link {
    width: 25%;
  }
  #social .s-link:hover {
    width: 25%;
  }
}
@media all and (max-width: 575px) {
  h1,
  h2,
  h3 {
    font-size: 30px !important;
  }
}
