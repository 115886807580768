@charset "UTF-8";
body, html {
    font-family: 'Montserrat', sans-serif;
    margin: 0 auto !important;
    padding: 0 !important;
    font-size: 100%;
    font-weight: normal;
    font-display: swap;
    color: @default;
    background: #eee;
    // overflow-x: hidden !important;
}

p {word-wrap: break-word !important;}
* {&:focus,&:hover {outline-color: transparent !important;}}
img {max-width: 100%;}
a {&:hover {text-decoration: none;}&:focus {text-decoration: none;}}

.shadow-0 {box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);}
.shadow-1 {box-shadow: 0 1px 3px rgba(0,0,0,.16),0 1px 3px rgba(0,0,0,.12);}
.shadow-2 {box-shadow: 0 3px 6px rgba(0,0,0,.16) , 0 3px 6px rgba(0,0,0,.23);}
.shadow-3 {box-shadow: 0 10px 20px rgba(0,0,0,.19) , 0 6px 6px rgba(0,0,0,.23);}
.shadow-4 {box-shadow: 0 14px 28px rgba(0,0,0,.25) , 0 10px 10px rgba(0,0,0,.22);}

@default: #454544;
@primary: #436fdd;
@secondary: #4bbe50;
@tertiary: #f8f8f8;

.def() {position: relative;width: 100%;height: auto;}

ul.custom {
    list-style: square;
    color: @secondary;
    padding-left: 15px;
    li {
        margin: 2px 0;
        span {
            color: @default;
        }
    }
}

h1, h2, h3, h4 {
    font-weight: 700;
}

b {
    color: @secondary;
}

blockquote {
    border-left: 3px solid @secondary;
    padding: 5px 8px;
    font-size: .95rem;
    font-style: italic;
    background: @tertiary;
}

#social {
    position: fixed;
    left: 0;
    bottom: ~"calc(50% - 120px)";
    width: 48px;
    display: flex;
    flex-direction: column;
    z-index: 9;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    .s-link {
        width: 48px;
        height: 48px;
        padding: 13px 0;
        background: #fff;
        transition: width 0.15s ease-in-out;
        svg {
            display: block;
            margin: 0 auto;
            width: 22px;
            height: 22px;
            fill: #666;
        }
        &:hover {
            width: 65px;
            svg {
                fill: @primary;
            }
        }
    }
}